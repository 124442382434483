import { Badge, Box, Divider, Grid, List, ListItem, Stack, Typography } from '@mui/material';
import { makeStyles, styled, useTheme } from '@mui/styles';
import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import helpdeskActive from '../../../../assets/images/icons/helpdesk-active.svg';
import TicketService from 'service/ticket.service';
import { ButtonNotification, PopupNotification } from '../NotificationComponent';
import { dynamicBoxShadow } from 'helpers/cardShadow';
import MarkUnreadChatAltOutlinedIcon from '@mui/icons-material/MarkUnreadChatAltOutlined';
import { useNavigate } from 'react-router';

const ListItemWrapper = styled('div')(({ theme }) => ({
    cursor: 'pointer',
    padding: 16,
    '&:hover': {
        background: theme.palette.primary.light
    },
    '& .MuiListItem-root': {
        padding: 0
    }
}));

const useStyles = makeStyles(() => ({
    textNotif: {
        background: '#EB001B',
        color: 'white',
        width: '10px',
        height: '10px',
        textAlign: 'center',
        borderRadius: '50%',
        position: 'relative',
        left: '10px',
        top: '8px'
    }
}));

const TicketNotification = () => {
    const theme = useTheme();
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [open, setOpen] = useState(false);

    const anchorRefs = useRef(null);

    const ticketNotification = useSelector((state) => state.notificationReducer.all_unread_notifcation);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const getTicketUnread = async () => {
        try {
            const response = await TicketService.getUnreadTicketNotification();
            dispatch({ type: 'UNREAD_TICKET_NOTIFICATION', payload: response.data.data.content });
        } catch (error) {
            // Handle the error
        }
    };

    const handleClose = (event) => {
        if (anchorRefs.current?.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    useEffect(() => {
        getTicketUnread();
        const intervalId = setInterval(() => {
            getTicketUnread();
        }, 30000);

        return () => clearInterval(intervalId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleClick = (res) => {
        navigate(`/helpdesk/chat/${res.number}`);
        handleClose();
    };

    return (
        <Box
            sx={{
                [theme.breakpoints.down('md')]: {
                    mr: 2
                }
            }}
        >
            <ButtonNotification anchorRef={anchorRefs} handleToggle={handleToggle} open={open}>
                {ticketNotification.length === 0 ? (
                    <img src={helpdeskActive} alt="" />
                ) : (
                    <>
                        <Badge sx={{ position: 'relative', top: '-8px', left: '15px' }} variant="dot" color="error"></Badge>
                        <img src={helpdeskActive} alt="" />
                    </>
                )}
            </ButtonNotification>

            <PopupNotification open={open} anchorEl={anchorRefs.current} handleClose={handleClose}>
                <Grid container mb={2} alignItems="center" sx={{ pt: 2, px: 2 }}>
                    <Grid xs={12} display="flex" alignItems={'center'} item>
                        <Box mr={1} sx={{ boxShadow: dynamicBoxShadow, borderRadius: '8px', padding: '7px 8px' }}>
                            <img src={helpdeskActive} alt="" />
                        </Box>
                        <Stack direction="row" spacing={2}>
                            <Box>
                                <Typography fontWeight="bold" fontSize="16px" variant="subtitle1">
                                    Ticket Notification
                                </Typography>
                                <Typography color="#959595" fontSize="14px">
                                    Get the latest updates on your tickets.
                                </Typography>
                            </Box>
                        </Stack>
                    </Grid>
                </Grid>
                <Divider sx={{ my: 0 }} />

                <List
                    sx={{
                        py: 0,

                        '& .MuiListItemSecondaryAction-root': {
                            top: 22
                        },
                        '& .MuiDivider-root': {
                            my: 0
                        }
                    }}
                >
                    {
                        // eslint-disable-next-line react/prop-types
                        ticketNotification?.length === 0 ? (
                            <ListItemWrapper>
                                <Typography
                                    textAlign={'center'}
                                    sx={{ maxWidth: '350px', paddingRight: '0' }}
                                    align="center"
                                    fontSize="12px"
                                    variant="subtitle2"
                                >
                                    You have no new notifications at this time.
                                </Typography>
                            </ListItemWrapper>
                        ) : (
                            <>
                                {
                                    // eslint-disable-next-line react/prop-types
                                    ticketNotification?.map((res) => (
                                        <React.Fragment key={res.last_message_at}>
                                            <ListItemWrapper
                                                onClick={() => handleClick(res)}
                                                sx={{ background: res.read_at === null ? theme.palette.primary.light : 'none' }}
                                            >
                                                <ListItem alignItems="center">
                                                    <Box mt={0.5}>
                                                        <Box
                                                            mb={1}
                                                            maxWidth={'330px'}
                                                            gap={2}
                                                            display={'flex'}
                                                            flexWrap={'wrap'}
                                                            alignItems={'center'}
                                                            justifyContent={'space-between'}
                                                        >
                                                            <Typography fontFamily={'Manrope Semibold'} color={'primary'} fontSize={'12px'}>
                                                                {res.number}
                                                            </Typography>

                                                            <Typography fontSize={'9px'}>{res?.last_message_at}</Typography>
                                                        </Box>

                                                        <Box
                                                            display={'flex'}
                                                            alignItems={'center'}
                                                            justifyContent={'space-between'}
                                                            gap={2}
                                                        >
                                                            <Typography fontFamily={'Manrope Semibold'} color={'#1D243C'}>
                                                                {res.subject}
                                                            </Typography>

                                                            <Box>
                                                                <Typography
                                                                    className={classes.textNotif}
                                                                    fontFamily={'Manrope Semibold'}
                                                                    fontSize={'8px'}
                                                                >
                                                                    {res.unread_messages}
                                                                </Typography>
                                                                <MarkUnreadChatAltOutlinedIcon fontSize="20px" color="primary" />
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </ListItem>
                                            </ListItemWrapper>
                                            <Divider />
                                        </React.Fragment>
                                    ))
                                }
                            </>
                        )
                    }
                </List>
            </PopupNotification>
        </Box>
    );
};

export default TicketNotification;
